import React from 'react';
import '../index.css';

import PhotoGrid from '../components/PhotoGrid';

function Home() {
  return (
    <div>
      <div className="title">
          <h1>lars</h1>
      </div>
      <PhotoGrid/>
    </div>
  );
}

export default Home;
